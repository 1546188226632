/* Light Theme */
$font-color: #5e5e5e;
$font-color-hover: #2e2e2e;
$font-color-inerit: #ffffff;
$background-color: #f8f8fb;
$background-inerit: #ffffff;
$box-shadow: 0 0 1.875em 0.5rem rgb(113 113 113 / 17%);
$box-shadow-nav:  0.0625rem 0.0625rem 1.875rem 0 rgba(128, 128, 128, 0.1);
$box-shadow-color: rgba(12 12 12 / 19%);
$border-color: #e0e6ed;
$input-border: #e6e6e6;
$input-border-focus: #a0a0a0;
$placeholder: #d4d4d4;


/* Dark Theme */
$font-color-dark: #9a9a9a;
$font-color-hover-dark: #e9e9e9;
$font-color-inerit-dark: #000000;
$background-color-dark: #2c2c2c;
$background-inerit-dark: #000000;
$box-shadow-dark: 0 0 1.875em 0.5rem rgb(255 255 255 / 17%);
$box-shadow-dark-color: rgb(255 255 255 / 10%);
$box-shadow-nav-dark: 0.1875rem 0.1875rem 1.875rem 0 rgba(113, 113, 113, 0.28);
$border-color-dark: #252525;
$input-border-dark: #3f3f3f;
$input-border-dark-focus: #aaaaaa;
$placeholder-dark: #5c5c5c;


/* Root Colors Theme */
:root {

    /* Blue Colors Theme */
    --color-primary-blue: #524af2;
    --color-primary-blue-rgb: 82, 74, 242, 0.15;
    --color-primary-blue-rgb-svg: 82, 74, 242;
    --color-primary-blue-contrast: #ffffff;
    --color-primary-blue-tint: #635cf3;

    --color-secondary-blue: #0096c7;
    --color-secondary-blue-rgb: 0, 150, 199, 0.15;
    --color-secondary-blue-contrast: #ffffff;
    --color-secondary-blue-tint: #1aa1cd;

    --color-tertiary-blue: #6f42c1;
    --color-tertiary-blue-rgb: 111, 66, 193, 0.15;
    --color-tertiary-blue-contrast: #ffffff;
    --color-tertiary-blue-tint: #7d55c7;

    /* Orange Colors Theme */
    --color-primary-orange: #FF4838;
    --color-primary-orange-rgb: 255, 72, 56, 0.15;
    --color-primary-orange-rgb-svg: 255, 72, 56;
    --color-primary-orange-contrast: #ffffff;
    --color-primary-orange-tint: #fc6557;

    --color-secondary-orange: #ffa200;
    --color-secondary-orange-rgb: 255, 162, 0, 0.15;
    --color-secondary-orange-contrast: #ffffff;
    --color-secondary-orange-tint: #ffab1a;

    --color-tertiary-orange: #BD1E51;
    --color-tertiary-orange-rgb: 189, 30, 81, 0.15;
    --color-tertiary-orange-contrast: #ffffff;
    --color-tertiary-orange-tint: #c43562;

    /* Green Colors Theme */

    --color-primary-green: #06de67;
    --color-primary-green-rgb: 6,222,103, 0.15;
    --color-primary-green-rgb-svg: 6,222,103;
    --color-primary-green-contrast: #ffffff;
    --color-primary-green-tint: #1fe176;

    --color-secondary-green: #1c4d7d;
    --color-secondary-green-rgb: 28,77,125, 0.15;
    --color-secondary-green-contrast: #ffffff;
    --color-secondary-green-tint: #335f8a;

    --color-tertiary-green: #535474;
    --color-tertiary-green-rgb: 83,84,116, 0.15;
    --color-tertiary-green-contrast: #ffffff;
    --color-tertiary-green-tint: #646582;

    /* Global Colors Theme */
    --color-success: #00c54e;
    --color-success-rgb: 0,197,78, 0.15;
    --color-success-contrast: #ffffff;
    --color-success-tint: #1acb60;

    --color-warning: #ffc409;
    --color-warning-rgb: 255, 196, 9, 0.15;
    --color-warning-contrast: #ffffff;
    --color-warning-tint: #ffca22;

    --color-danger: #eb445a;
    --color-danger-rgb: 235, 68, 90, 0.15;
    --color-danger-contrast: #ffffff;
    --color-danger-tint: #ed576b;

    --color-dark: #222428;
    --color-dark-rgb: 34, 36, 40, 0.15;
    --color-dark-contrast: #ffffff;
    --color-dark-tint: #383a3e;

    --color-medium: #e4e6ef;
    --color-medium-rgb: 228,230,239, 0.15;
    --color-medium-contrast: #5f5f5f;
    --color-medium-tint: #e7e9f1;

    --color-light: #f4f5f8;
    --color-light-rgb: 244,245,248, 0.15;
    --color-light-contrast: #2e2e2e;
    --color-light-tint: #f5f6f9;

    --color-muted: #6c757d;
    --color-muted-rgb: 108, 117, 125, 0.15;
    --color-muted-contrast: #ffffff;
    --color-muted-tint: #7b838a;

    --color-transparent: rgb(0, 0, 0, 0);
    --color-transparent-contrast: #92949c;
}
